<script setup lang="ts">

const isLoading = ref(true)

onMounted(async () => {
  await nextTick();
  const minimumLoadTime = new Promise<void>((resolve) => setTimeout(resolve, 1000));
  await Promise.all([waitForLoad(), minimumLoadTime]);
  isLoading.value = false;
});

// Wait for the page to be fully loaded before hiding the loader
const waitForLoad = () => new Promise<void>((resolve) => {
  if (document.readyState === 'complete') {
    resolve();
  } else {
    window.addEventListener('load', () => resolve(), { once: true });
  }
});
</script>

<template>
  <v-app>
    <div class="background">
      <UMain>
        <slot />
      </UMain>
    </div>
  </v-app>
</template>